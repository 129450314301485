<template>
  <div class="noticia">
    <navbar />
    <div class="hero is-primary is-medium is-bold">
      <div class="hero-body">
        <img v-if="noticia.thumbnail !== ''" class="hero-img" :src="`${API_URL}/public/news/thumbnails/${noticia.thumbnail}`" alt="" />
      </div>
    </div>
    <div class="container">
      <section class="articles">
        <div class="column is-8 is-offset-2">
          <div class="card article">
            <div class="card-content">
              <div class="media">
                <div class="media-content has-text-centered">
                  <p class="title article-title">{{ noticia.title }}</p>
                  <div class="tags has-addons level-item">
                      <span class="tag is-rounded is-primary">{{noticia.author}}</span>
                      <span class="tag is-rounded">
                        {{format(noticia.createdAt, 'LLL dd, yyyy')}}
                      </span>
                  </div>
                </div>
              </div>
              <div class="content article-body" v-html="noticia.text">
              </div>
              <div class="media">
                <div class="media-content has-text-centered">
                  <a class="rede has-text-primary mr-2 facebook" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-facebook" /></a>
                  <a class="rede has-text-primary instagram" href="https://www.instagram.com/coopenaza_oficial/"><i class="lni lni-instagram" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import { format } from 'date-fns'
import marked from 'marked'
import { api, API_URL } from '@/api'
import Navbar from '@/layout/Navbar'
import Footer from '@/layout/Footer'

export default {
  name: 'Noticia',
  components: {
    Navbar,
    Footer
  },
  created () {
    api.get(`/news/${this.$route.params.id}`).then(res => {
      this.noticia = res.data
      this.noticia.text = marked(res.data.text)
    })
  },
  data () {
    return {
      noticia: {
        id: 0,
        createdAt: new Date().toString(),
        thumbnail: ''
      }
    }
  },
  computed: {
    API_URL () {
      return API_URL
    }
  },
  methods: {
    format (date, pattern) {
      return format(new Date(date), pattern)
    }
  }
}
</script>

<style lang="scss" scoped>
.noticia {
  .hero {
    position: relative;
    &-body {
      height: 500px;
      .hero-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .container {
    .articles {
      margin: 5rem 0;
      margin-top: -200px;
      .article {
        .media {
          margin-bottom: 1.5rem;
          .media-content {
            margin-top: 3rem;
          }
        }
        .article-body {
          text-align: justify;
          margin: 0 2rem;
        }
      }
    }
  }
}
</style>
